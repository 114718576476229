import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Team = (props) => {
  const teams = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-teams">
      <SEO title="Team" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="about-us">¿Quiénes somos?</h1>
              <p className="about-us">
                Porque tu salario es el resultado de la dedicación y esfuerzo en
                tu trabajo, en CONCILIACIÓN LABORAL nos ponemos de tu lado
                brindando toda nuestra experiencia y herramientas necesarias
                para proteger tu dinero como se merece, contamos con abogados
                dispuestos a atender tus inquietudes, dudas y problemas. La
                experiencia en el ramo laboral está de nuestro lado y tenemos al
                personal capacitado para atenderte de manera excepcional.
                <br />
                <br />
                Ofrecemos una variedad de servicios que abarcan desde la
                asesoría legal al acompañamiento en el caso de así requerirse,
                no es necesario acudas a nuestras oficinas, puedes comunicarte
                vía telefónica o por medio de whatsapp donde sabremos darte
                atención oportuna, veraz y de gran valor.
                <br />
                <br />
                CONCILIACIÓN LABORAL tiene la misión de ver por los intereses de
                los trabajadores ante situaciones adversas en su ambiente
                laboral con la intención de hacer valer lo que el fruto de su
                trabajo a brindado, permitenos ser parte de la defensa de tu
                dinero, de tus intereses y de tu trabajo.
                <br />
                <br />
                Somos un despacho joven y de gran experiencia en el medio, así
                como gran conocimiento en el área.
                <br/>
                <br/>
                <strong>El equipo de CONCILIACIÓN LABORAL.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6 people">
        <div className="row">
          {teams.map(edge => (
            <div
              key={edge.node.frontmatter.path}
              className="col-12 col-md-6 mb-1"
            >
              <div className="team card-two">
                <div className="card-header">
                  <div className="card-header-left">
                    {edge.node.frontmatter.image && (
                      <div className="card-image">
                        <img
                          alt={edge.node.frontmatter.title}
                          className="img-fluid mb-2"
                          src={edge.node.frontmatter.image}
                        />
                      </div>
                    )}
                  </div>
                  <div className="card-right">
                    <h2 className="card-title">
                      {edge.node.frontmatter.title}
                    </h2>
                    <ul className="card-meta">
                      <li>
                        <strong>{edge.node.frontmatter.jobtitle}</strong>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href={edge.node.frontmatter.linkedinurl}
                        >
                          {edge.node.frontmatter.linkedinurl}
                        </a>
                      </li>
                      <li>
                        <a href={edge.node.frontmatter.email}>
                          {edge.node.frontmatter.email}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="team-content"
                  dangerouslySetInnerHTML={{ __html: edge.node.html }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/team/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            image
            jobtitle
            linkedinurl
            email
          }
        }
      }
    }
  }
`;

export default Team;
